.p-inputwrapper-filled~label {
    font-size: 11px !important;
}

/* .p-datepicker {
    top: 29px !important;
} */

.p-datepicker .p-timepicker span {
    font-size: 1.05rem;
}

.p-datepicker table td {
    padding: 0.25rem;
}

.p-timepicker {
    padding: 0.25rem !important;
}

.p-datepicker-header {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.p-datepicker-buttonbar {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;}
/* body {
    position: relative;
} */